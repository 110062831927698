.collage {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    padding: 20px;
    width: 100%; /* Ensure the collage takes the full width of its container */
}
.image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
}

.image:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 25px rgba(255, 255, 255, 0.2);
    cursor: pointer;
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.modal-content {
    max-height: 80%;
    max-width: 80%;
    border-radius: 10px;
    position: relative;
}

.arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    fill: white;
    /* SVG icon color */
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: white;
    font-size: 40px;
    cursor: pointer;
}

.caption {
    color: white;
    font-size: 15px;
    text-align: center;
    position: absolute;
    bottom: 60px;
    width: 100%;
}

.social-icons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: white;
}

.modal img {
    border-radius: 8px;
}


.social-icons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    /* Adjust the size of the icons */
    color: white;
}

.social-icons>* {
    margin: 0 5px;
    /* Adjust the spacing between icons */
}